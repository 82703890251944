import { ScopedPermissions } from '@air/api';
import { permissionsSelector, setPermissionsAction } from '@air/redux-permissions';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useAirStore } from '~/utils/ReduxUtils';

export const useAssetPermissionsCache = () => {
  const store = useAirStore();
  const dispatch = useDispatch();

  const getAssetPermissions = useCallback(
    (assetId: string) => permissionsSelector(store.getState(), 'assetIds', assetId),
    [store],
  );

  const setAssetPermissions = useCallback(
    ({ assetId, permissions }: { assetId: string; permissions: ScopedPermissions | undefined }) =>
      dispatch(
        setPermissionsAction({
          assetIds: {
            [assetId]: permissions,
          },
        }),
      ),
    [dispatch],
  );

  return {
    getAssetPermissions,
    setAssetPermissions,
  };
};
