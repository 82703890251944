import { AirActionTrackingLocation, useTrackDownloadedItems } from '@air/analytics';
import { Clips } from '@air/api';
import { type Clip } from '@air/api/types';
import { useToasts } from '@air/provider-toast';
import { reportErrorToBugsnag } from '@air/utils-error';
import { useCallback } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { BulkDownloadFiles, isS3DownloadUrlActive } from '~/utils/DownloadFile';

export type BulkDownloadPrivateAssetsParams = {
  clipIds: Clip['id'][];
  trackLocation: AirActionTrackingLocation;
};

export const useBulkDownloadPrivateAssets = () => {
  const { showToast } = useToasts();
  const { currentWorkspace } = useCurrentWorkspace();
  const { trackDownloadedItems } = useTrackDownloadedItems();

  const bulkDownloadPrivateAssets = useCallback(
    ({ clipIds, trackLocation }: BulkDownloadPrivateAssetsParams) => {
      if (!currentWorkspace?.id) {
        throw new Error('No workspace id');
      }

      const urls = clipIds.map(async (id) => {
        const { url } = await Clips.getDownloadUrl({ clipId: id, workspaceId: currentWorkspace.id });

        return url;
      });

      Promise.all(urls)
        .then((urls) => {
          const activeUrls = urls.filter((url) => isS3DownloadUrlActive(url));

          BulkDownloadFiles(activeUrls);

          if (activeUrls.length < urls.length) {
            showToast('One or more of the selected file downloads has expired.');
          }
        })
        .catch((error) => {
          showToast('There was an error downloading a private asset.');

          reportErrorToBugsnag({
            error,
            context: 'Failed to download private assets from selection menu',
            metadata: {
              data: {
                clipIds,
                location: trackLocation,
              },
            },
          });
        });

      trackDownloadedItems({
        clipIds,
        location: trackLocation,
      });
    },
    [currentWorkspace?.id, showToast, trackDownloadedItems],
  );

  return { bulkDownloadPrivateAssets };
};
