import type { Updater } from '@tanstack/react-query';
import { type ColumnSizingState } from '@tanstack/react-table';
import { throttle } from 'lodash';

import { LOCAL_STORAGE_BASE_KEY } from '~/constants/localStorageKeys';

import { ActiveTableColumn, getTableColumnWidth } from './types';

const TABLE_COLUMN_WIDTHS_KEY = `${LOCAL_STORAGE_BASE_KEY}/tableColumnWidths`;
const getLocalStorageKey = (boardId: string): string => `${TABLE_COLUMN_WIDTHS_KEY}/${boardId}`;
export const getWidthsFromLocalStorage = (boardId: string): Record<string, number> => {
  try {
    const existingWidths = localStorage.getItem(getLocalStorageKey(boardId));

    return existingWidths ? JSON.parse(existingWidths) : {};
  } catch {
    return {};
  }
};
export const saveWidthsToLocalStorage = (boardId: string, currentWidths: Record<string, number>): void => {
  localStorage.setItem(getLocalStorageKey(boardId), JSON.stringify(currentWidths));
};
export const saveWidthsToLocalStorageThrottled = throttle((boardId: string, currentWidths: Record<string, number>) => {
  const existingWidths = getWidthsFromLocalStorage(boardId);
  const updatedWidths = { ...existingWidths, ...currentWidths };
  saveWidthsToLocalStorage(boardId, updatedWidths);
}, 1_000);

export const getColumnWidthMap = (
  activeTableColumns: ActiveTableColumn[],
  currentWidths: Record<string, number>,
): Map<string, number> => {
  const columnWidthMap = new Map<string, number>();
  for (const column of activeTableColumns) {
    const columnDef = currentWidths[column.fieldName];
    if (column.isCustomField === false) {
      columnWidthMap.set(column.fieldName, columnDef ? columnDef : getTableColumnWidth(column.fieldName));
    } else {
      columnWidthMap.set('customField', getTableColumnWidth('customField'));
    }
  }

  return columnWidthMap;
};

// Custom fields with special characters are sanitized to be used as CSS variable names
const sanitizeFieldName = (fieldName: string) => fieldName.replace(/[^a-zA-Z0-9]/g, '-');
const getColumnWidthVariableName = (boardId: string, fieldName: string) =>
  `--table-column-width-${boardId}-${sanitizeFieldName(fieldName)}`;
export const getColumnWidthVariable = (boardId: string, fieldName: string) =>
  `var(${getColumnWidthVariableName(boardId, fieldName)})`;

const getTableWidthVariableName = (boardId: string): string => `--table-width-${boardId}`;
export const getTableWidthVariable = (boardId: string): string => `var(${getTableWidthVariableName(boardId)})`;
export const getTableWidthVariableValue = (boardId: string): string | undefined =>
  typeof document !== 'undefined'
    ? getComputedStyle(document.documentElement).getPropertyValue(getTableWidthVariableName(boardId))
    : undefined;

const getStyleId = (boardId: string) => `table-column-widths-${boardId}`;

const updateStyleElement = (boardId: string, cssVariablesList: string[]): void => {
  const styleId = getStyleId(boardId);
  let styleElement = document.getElementById(styleId) as HTMLStyleElement;

  if (!styleElement) {
    styleElement = document.createElement('style');
    styleElement.id = styleId;
    document.head.appendChild(styleElement);
  }

  styleElement.textContent = `:root {\n${cssVariablesList.join('\n')}\n}`;
};

export const setTableDimensionsAsCSSVariables = (
  boardId: string,
  activeTableColumns: ActiveTableColumn[],
  columnWidthMap: Map<string, number>,
) => {
  const tableWidth = activeTableColumns.reduce(
    (acc, column) =>
      acc +
      (columnWidthMap.get(column.fieldName) ??
        getTableColumnWidth(column.isCustomField ? 'customField' : column.fieldName)),
    0,
  );

  const cssVariablesList: string[] = [];
  for (const [fieldName, width] of columnWidthMap.entries()) {
    cssVariablesList.push(`${getColumnWidthVariableName(boardId, fieldName)}: ${width}px;`);
  }
  cssVariablesList.push(`${getTableWidthVariableName(boardId)}: ${tableWidth}px;`);

  updateStyleElement(boardId, cssVariablesList);
};

export const makeUpdaterForColumnSizing = (columnSizing: ColumnSizingState) => {
  const updater: Updater<ColumnSizingState, ColumnSizingState> = () => {
    return columnSizing;
  };

  return updater;
};

const MIN_COLUMN_WIDTH = 130;
const MIN_COLUMN_WIDTH_MAP = {
  title: 240,
} as const;
export const getMinColumnWidth = (fieldName: string) =>
  MIN_COLUMN_WIDTH_MAP[fieldName as keyof typeof MIN_COLUMN_WIDTH_MAP] || MIN_COLUMN_WIDTH;

const MAX_COLUMN_WIDTH = 640;
const MAX_COLUMN_WIDTH_MAP = {
  title: Number.MAX_SAFE_INTEGER,
} as const;
export const getMaxColumnWidth = (fieldName: string) =>
  MAX_COLUMN_WIDTH_MAP[fieldName as keyof typeof MAX_COLUMN_WIDTH_MAP] || MAX_COLUMN_WIDTH;

export const getInitialColumnWidth = (fieldName: string) =>
  Math.max(getMinColumnWidth(fieldName), getTableColumnWidth(fieldName));
