import { RequireAtLeastOne, RequireOnlyOne, SearchFilterLocation, SearchFilterString } from '@air/types';

// location can be filtered as separate cities, states and countries
// or as an object with city, state, and country
// We support only the first option for now in UI
export type SeparateLocationParam = RequireAtLeastOne<{
  city?: RequireOnlyOne<SearchFilterString>;
  state?: RequireOnlyOne<SearchFilterString>;
  country?: RequireOnlyOne<SearchFilterString>;
}>;

export const isLocationParamSeparate = (
  locationParam: SearchFilterLocation,
): locationParam is SeparateLocationParam => {
  return 'city' in locationParam || 'state' in locationParam || 'country' in locationParam;
};
