import { PublicClip } from '@air/api';
import { Board, Clip, PublicBoard } from '@air/api/types';
import { Upload } from '@air/redux-uploader';

import { GalleryItemType } from '~/components/Gallery/types';
import { TableRowType } from '~/components/TableView/basicTypes';

export interface BasicTableRowProps<T> {
  data: T;
}

export interface AirTableMetadata {
  isSelectable: boolean;
  type: GalleryItemType.board | GalleryItemType.file | GalleryItemType.upload | GalleryItemType.asset;
  item: PublicBoard | Board | Clip | PublicClip | Upload;
}

export interface AirTableRow extends TableRowType {
  getMetadata?: () => AirTableMetadata;
}

export const getTableColumnWidth = (key: string) => {
  switch (key) {
    case 'title':
      return 400;
    case 'buttons':
      return 68;
    case 'dateCreated':
    case 'dateTaken':
      return 160;
    case 'dateModified':
      return 170;
    case 'dateUploaded':
      return 190;
    case 'uploadedBy':
      return 220;
    case 'ext':
      return 115;
    case 'resolution':
      return 125;
    case 'size':
      return 90;
    case 'tagCount':
      return 110;
    case 'openCommentCount':
      return 150;
    case 'boardCount':
      return 120;
    case 'customField':
      return 240;
    case 'type':
      return 90;
    default:
      return 160;
  }
};

export interface ActiveTableColumn {
  fieldName: string;
  isCustomField: boolean;
}

export const getMinTableWidth = (activeTableColumns: ActiveTableColumn[]): number => {
  return activeTableColumns.reduce(
    (acc, key) => acc + getTableColumnWidth(key.isCustomField ? 'customField' : key.fieldName),
    0,
  );
};

export const getMinTableContentWidth = (activeTableColumns: ActiveTableColumn[]) =>
  getMinTableWidth(activeTableColumns) + 86; // width + padding

export const centeredTableCells = ['tagCount', 'openCommentCount', 'boardCount'];

export type OnRenameFunction = () => void;
