import { PublicClip } from '@air/api';
import { Board, Clip, PublicBoard } from '@air/api/types';
import { type AvatarProps } from '@air/primitive-avatar';
import { Upload } from '@air/redux-uploader';
import { formatBytes, parseAirDateToISO } from '@air/utilities';
import { reportErrorToBugsnag } from '@air/utils-error';
import { format } from 'date-fns';
import { isString } from 'lodash';

import { GalleryItemType } from '~/components/Gallery/types';
import { ConfigVisibilityToggleType } from '~/constants/configViews';
import { SelectableGalleryItem } from '~/store/selectedItems/types';
import { UseGalleryMixedItem } from '~/swr-hooks/gallery/types';
import { getAssetGalleryItemType } from '~/utils/AssetUtils';
import { getAvatarImageSrc } from '~/utils/getAvatarImageSrc';

const BLANK_CELL_TEXT = '--';

const DATE_FORMAT = 'MMM d, yyyy';
const DATE_TIME_FORMAT = 'MMM d, yyyy h:mm a';
type DateFormat = typeof DATE_FORMAT | typeof DATE_TIME_FORMAT;

export const formatTableViewDate = (date: string, dateFormat: DateFormat = DATE_FORMAT) => {
  // new Date(date) shows Invalid Date on Safari and Firefox for some of our dates
  // 2020-03-24 16:32:12.16+00: '.16+00' are the problem so we split to remove it
  // 2020-06-20T00:54:10.897Z: 'T' and 'Z' are the problem so we replace them
  return format(parseAirDateToISO(date), dateFormat);
};

export const safeFormatDate = <T extends object, K extends keyof T>(
  object: T,
  property: K,
  dateFormat: DateFormat = DATE_FORMAT,
): string => {
  try {
    const value = object[property];
    if (typeof value === 'string') {
      return formatTableViewDate(value, dateFormat);
    }

    // non-string values are valid, we assume they are not dates
    // and return the placeholder blank cell value
    return BLANK_CELL_TEXT;
  } catch (e) {
    // catch errors related to malformed date strings
    reportErrorToBugsnag({
      error: e,
      context: `Invalid ${String(property)} date`,
      metadata: { object, property },
    });

    return BLANK_CELL_TEXT;
  }
};

export const boardTableRowInfo = (board: Board | PublicBoard): Record<ConfigVisibilityToggleType, string> => ({
  dateCreated: safeFormatDate(board, 'createdAt'),
  dateModified: safeFormatDate(board, 'updatedAt'),
  dateUploaded: safeFormatDate(board, 'createdAt', DATE_TIME_FORMAT),
  uploadedBy: BLANK_CELL_TEXT,
  dateTaken: BLANK_CELL_TEXT,
  ext: BLANK_CELL_TEXT,
  size: BLANK_CELL_TEXT,
  titleAndMetadata: BLANK_CELL_TEXT,
  type: BLANK_CELL_TEXT,
  resolution: BLANK_CELL_TEXT,
  tagCount: BLANK_CELL_TEXT,
  boardCount: BLANK_CELL_TEXT,
  openCommentCount: BLANK_CELL_TEXT,
});

export const uploadTableRowInfo = (upload: Upload): Record<ConfigVisibilityToggleType, string> => {
  const recordedAt = upload.apiUploadData.recordedAt;
  const createdAt = new Date(upload.createdAt).toISOString();
  let recordedAtString = createdAt;
  if (recordedAt) {
    if (isString(recordedAt)) {
      recordedAtString = recordedAt;
    } else {
      recordedAtString = recordedAt.toISOString();
    }
  }

  return {
    ext: upload.apiUploadData.ext,
    dateModified: formatTableViewDate(recordedAtString),
    dateCreated: formatTableViewDate(createdAt),
    dateTaken: formatTableViewDate(recordedAtString),
    dateUploaded: formatTableViewDate(createdAt, DATE_TIME_FORMAT),
    uploadedBy: BLANK_CELL_TEXT,
    size: formatBytes(upload.apiUploadData.size || 0),
    titleAndMetadata: BLANK_CELL_TEXT,
    type: BLANK_CELL_TEXT,
    resolution: BLANK_CELL_TEXT,
    tagCount: '0',
    boardCount: '0',
    openCommentCount: '0',
  };
};

export const assetTableRowInfo = (asset: Clip | PublicClip): Record<ConfigVisibilityToggleType, string> => ({
  ext: asset.ext,
  dateModified: safeFormatDate(asset, 'updatedAt'),
  dateCreated: safeFormatDate(asset, 'recordedAt'),
  dateTaken: safeFormatDate(asset, 'dateTaken'),
  dateUploaded: safeFormatDate(asset, 'createdAt', DATE_TIME_FORMAT),
  uploadedBy: asset.ownerName,
  size: formatBytes(asset.size),
  type: asset.type === 'nonMedia' ? 'file' : asset.type,
  titleAndMetadata: BLANK_CELL_TEXT,
  resolution: asset.width && asset.height ? `${asset.width} x ${asset.height}` : BLANK_CELL_TEXT,
  tagCount: `${asset.tags?.length ?? 0}`,
  boardCount: `${asset.boardCount ?? 0}`,
  openCommentCount: `${asset.openDiscussionCount || 0}`,
});

export const mixedItemToSelectable = (item: UseGalleryMixedItem) => {
  const itemType = item.type === 'board' ? GalleryItemType.board : getAssetGalleryItemType(item.item);

  return {
    id: item.item.id,
    type: itemType,
    item: item.item,
  } as SelectableGalleryItem;
};

const AVATAR_SIZE: AvatarProps['size'] = 24;
export const getUploadedByAvatarProps = (
  item: Clip | PublicClip,
): Pick<AvatarProps, 'alt' | 'size' | 'src' | 'text' | 'colorSeed'> => ({
  alt: `${item.owner.ownerName} avatar`,
  size: AVATAR_SIZE,
  src: getAvatarImageSrc(item.owner.ownerAvatar, AVATAR_SIZE),
  text: item.owner.ownerInitials,
  colorSeed: item.accountId,
});
