import { useBreakpointsContext } from "@air/provider-media-query";
import { useAirModal } from "@air/provider-modal";
import noop from "lodash/noop";
import { useCallback } from "react";
import { MobileFiltersSubMenu } from "~/components/FiltersMenu/MobileFiltersSubMenu";
import { sortFiltersByOrder } from "~/components/FiltersMenu/types";
import { getAudioVisualFiltersOptions } from "~/components/FiltersMenu/utils/getAudioVisualFiltersOptions";
import { getCustomFieldsFilterOptions } from "~/components/FiltersMenu/utils/getCustomFieldFiltersOptions";
import { getDateFiltersOptions } from "~/components/FiltersMenu/utils/getDateFiltersOptions";
import { getFilterProps } from "~/components/FiltersMenu/utils/getFilterProps";
import { getLocationFiltersOptions } from "~/components/FiltersMenu/utils/getLocationFiltersOptions";
import { isAudioVisualFilter, isDateFilter, isLocationFilter } from "~/components/FiltersMenu/utils/typeChecks";
export const useFiltersMenuOptions = ()=>{
    const { isAboveMediumScreen } = useBreakpointsContext();
    const [showMobileFiltersSubMenu] = useAirModal(MobileFiltersSubMenu);
    const getFiltersMenuOptions = useCallback((param)=>{
        let { selectedFilters = [], onSelect, customFields, availableFilters } = param;
        let filtersToShow = availableFilters;
        if (!customFields.length) {
            filtersToShow = availableFilters.filter((filter)=>filter !== "customField");
        }
        // date filters should be visible as suboption - filter them out from this array
        const dateFilters = availableFilters.filter(isDateFilter).sort(sortFiltersByOrder);
        filtersToShow = filtersToShow.filter((filter)=>!isDateFilter(filter));
        const locationFilters = availableFilters.filter(isLocationFilter).sort(sortFiltersByOrder);
        filtersToShow = filtersToShow.filter((filter)=>!isLocationFilter(filter));
        const audioVisualFilters = availableFilters.filter(isAudioVisualFilter).sort(sortFiltersByOrder);
        filtersToShow = filtersToShow.filter((filter)=>!isAudioVisualFilter(filter));
        // if there is no 'date' filter, but we have any date filters included, add 'date' filter (parent of date suboptions)
        if (!filtersToShow.includes("date") && dateFilters.length > 0) {
            filtersToShow.splice(filtersToShow.length - 1, 0, "date");
        } else if (dateFilters.length === 0 && filtersToShow.includes("date")) {
            filtersToShow = filtersToShow.filter((filter)=>filter !== "date");
        }
        if (!filtersToShow.includes("audioVisual") && audioVisualFilters.length > 0) {
            filtersToShow.splice(filtersToShow.length - 1, 0, "audioVisual");
        } else if (audioVisualFilters.length === 0 && filtersToShow.includes("audioVisual")) {
            filtersToShow = filtersToShow.filter((filter)=>filter !== "audioVisual");
        }
        // if there is no 'location' filter, but we have any location filters included, add 'location' filter (parent of date suboptions)
        if (!filtersToShow.includes("location") && locationFilters.length > 0) {
            filtersToShow.splice(filtersToShow.length - 1, 0, "location");
        } else if (dateFilters.length === 0 && filtersToShow.includes("location")) {
            filtersToShow = filtersToShow.filter((filter)=>filter !== "location");
        }
        return filtersToShow.map((filterType)=>{
            if (filterType === "customField" && !!customFields.length) {
                return {
                    ...getFilterProps({
                        type: "customField",
                        onSelect: !isAboveMediumScreen ? ()=>showMobileFiltersSubMenu({
                                options: getCustomFieldsFilterOptions({
                                    customFields,
                                    selectedFilters,
                                    onSelect
                                })
                            }) : noop
                    }),
                    options: getCustomFieldsFilterOptions({
                        customFields,
                        selectedFilters,
                        onSelect
                    }),
                    "data-id": "customField",
                    type: !isAboveMediumScreen ? "item" : "sub"
                };
            } else if (filterType === "location") {
                return {
                    ...getFilterProps({
                        type: "location",
                        onSelect: !isAboveMediumScreen ? ()=>showMobileFiltersSubMenu({
                                options: getLocationFiltersOptions({
                                    selectedFilters,
                                    locationFilters,
                                    onSelect
                                })
                            }) : noop
                    }),
                    "data-id": "location",
                    options: getLocationFiltersOptions({
                        selectedFilters,
                        locationFilters,
                        onSelect
                    }),
                    type: !isAboveMediumScreen ? "item" : "sub"
                };
            } else if (filterType === "audioVisual") {
                return {
                    ...getFilterProps({
                        type: "audioVisual",
                        onSelect: !isAboveMediumScreen ? ()=>showMobileFiltersSubMenu({
                                options: getAudioVisualFiltersOptions({
                                    selectedFilters,
                                    audioVisualFilters,
                                    onSelect
                                })
                            }) : noop
                    }),
                    "data-id": "audioVisual",
                    options: getAudioVisualFiltersOptions({
                        selectedFilters,
                        audioVisualFilters,
                        onSelect
                    }),
                    type: !isAboveMediumScreen ? "item" : "sub"
                };
            } else if (filterType === "date") {
                return {
                    ...getFilterProps({
                        type: "date",
                        onSelect: !isAboveMediumScreen ? ()=>showMobileFiltersSubMenu({
                                options: getDateFiltersOptions({
                                    selectedFilters,
                                    dateFilters,
                                    onSelect
                                })
                            }) : noop
                    }),
                    "data-id": "date",
                    options: getDateFiltersOptions({
                        selectedFilters,
                        dateFilters,
                        onSelect
                    }),
                    type: !isAboveMediumScreen ? "item" : "sub"
                };
            } else {
                return {
                    ...getFilterProps({
                        type: filterType,
                        onSelect,
                        isSelected: selectedFilters.some((param)=>{
                            let { type } = param;
                            return type === filterType;
                        })
                    }),
                    type: "item"
                };
            }
        });
    }, [
        isAboveMediumScreen,
        showMobileFiltersSubMenu
    ]);
    return {
        getFiltersMenuOptions
    };
};
