import { ScopedPermissions } from '@air/api';
import { permissionsSelector, setPermissionsAction } from '@air/redux-permissions';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useAirStore } from '~/utils/ReduxUtils';

export const useClipPermissionsCache = () => {
  const store = useAirStore();
  const dispatch = useDispatch();

  const getClipPermissions = useCallback(
    (clipId: string) => permissionsSelector(store.getState(), 'clipIds', clipId),
    [store],
  );

  const setClipPermissions = useCallback(
    ({ clipId, permissions }: { clipId: string; permissions: ScopedPermissions | undefined }) =>
      dispatch(
        setPermissionsAction({
          clipIds: {
            [clipId]: permissions,
          },
        }),
      ),
    [dispatch],
  );

  return {
    getClipPermissions,
    setClipPermissions,
  };
};
