import { useBreakpointsContext } from "@air/provider-media-query";
import constant from "lodash/constant";
import noop from "lodash/noop";
import { useCallback, useMemo } from "react";
import { GalleryItemType } from "~/components/Gallery/types";
import { useGallerySectionFooter } from "~/components/Gallery/useGallerySectionFooter";
import { useGallerySectionHeader } from "~/components/Gallery/useGallerySectionHeader";
import { isBoardDragType } from "~/components/Shared/Drag/dragTypes";
import { WKSPC_DESKTOP_HORIZONTAL_PADDING_WITH_SIDENAV_OPEN } from "~/constants/WorkspaceSpacing";
import useCSSGridLikeLayout from "~/hooks/useCSSGridLikeLayout";
export const BoardGalleryCardDesktopSize = {
    small: 153,
    medium: 204,
    large: 408,
    "extra-large": 612
};
export const BoardGalleryCardMobileSize = {
    small: 120,
    medium: 160,
    large: 220,
    "extra-large": 280
};
export const useGalleryBoards = (param)=>{
    let { renderBoard, data, containerWidth, loadMore = noop, containerHorizontalPadding = WKSPC_DESKTOP_HORIZONTAL_PADDING_WITH_SIDENAV_OPEN, itemHeight, isSelectable = constant(false), onSectionCollapse } = param;
    var _data_items, _data_total, _data_items1, _data_items2;
    const { isAboveSmallScreen, isAboveMediumScreen } = useBreakpointsContext();
    var _data_items_length;
    const { boxes: sectionBoxes } = useCSSGridLikeLayout({
        height: itemHeight,
        minWidth: itemHeight,
        itemCount: (_data_items_length = data === null || data === void 0 ? void 0 : (_data_items = data.items) === null || _data_items === void 0 ? void 0 : _data_items.length) !== null && _data_items_length !== void 0 ? _data_items_length : 0,
        containerWidth: containerWidth,
        itemHorizontalSpacing: !isAboveSmallScreen ? 8 : 24,
        containerHorizontalPadding: containerHorizontalPadding
    });
    const renderItem = useCallback((index)=>{
        const board = data === null || data === void 0 ? void 0 : data.items[index];
        return board && renderBoard ? renderBoard({
            box: sectionBoxes[index],
            data: board,
            index
        }) : null;
    }, [
        data === null || data === void 0 ? void 0 : data.items,
        renderBoard,
        sectionBoxes
    ]);
    const verticalItemsSpacing = useMemo(()=>{
        let verticalSpacing = 36;
        if (!isAboveMediumScreen) verticalSpacing = 24;
        if (!isAboveSmallScreen) verticalSpacing = 8;
        return verticalSpacing;
    }, [
        isAboveMediumScreen,
        isAboveSmallScreen
    ]);
    const getItemData = useCallback((index)=>{
        var _data_items_index;
        return {
            type: GalleryItemType.board,
            isSelectable: !!(data === null || data === void 0 ? void 0 : data.items[index]) ? isSelectable(data === null || data === void 0 ? void 0 : data.items[index]) : false,
            itemId: (data === null || data === void 0 ? void 0 : (_data_items_index = data.items[index]) === null || _data_items_index === void 0 ? void 0 : _data_items_index.id) || "",
            canDragTo: (item)=>{
                var _data_items_index_ancestors, _data_items_index;
                const isAncestorOfAnyBoard = !!(data === null || data === void 0 ? void 0 : (_data_items_index = data.items[index]) === null || _data_items_index === void 0 ? void 0 : (_data_items_index_ancestors = _data_items_index.ancestors) === null || _data_items_index_ancestors === void 0 ? void 0 : _data_items_index_ancestors.find((param)=>{
                    let { id } = param;
                    return id === item.id;
                }));
                if (isAncestorOfAnyBoard || item.mixedTypes) {
                    return false;
                }
                return isBoardDragType(item.type);
            }
        };
    }, [
        data === null || data === void 0 ? void 0 : data.items,
        isSelectable
    ]);
    const { isHeaderCollapsed, header } = useGallerySectionHeader({
        isFirstSection: true,
        title: "Boards (".concat(data === null || data === void 0 ? void 0 : (_data_total = data.total) === null || _data_total === void 0 ? void 0 : _data_total.toLocaleString(), ")"),
        containerWidth,
        containerPadding: containerHorizontalPadding,
        onSectionCollapse
    });
    const footer = useGallerySectionFooter({
        isLoading: !!(data === null || data === void 0 ? void 0 : data.isLoading),
        loadMore,
        hasMore: !!(data === null || data === void 0 ? void 0 : data.hasMore),
        containerWidth
    });
    if (!(data === null || data === void 0 ? void 0 : (_data_items1 = data.items) === null || _data_items1 === void 0 ? void 0 : _data_items1.length) && !(data === null || data === void 0 ? void 0 : data.isLoading)) {
        return null;
    }
    return {
        header: !!(data === null || data === void 0 ? void 0 : (_data_items2 = data.items) === null || _data_items2 === void 0 ? void 0 : _data_items2.length) ? header : undefined,
        footer: isHeaderCollapsed ? undefined : footer,
        items: {
            render: renderItem,
            boxes: isHeaderCollapsed ? [] : sectionBoxes,
            verticalItemsSpacing,
            getData: getItemData
        }
    };
};
